<template>
  <div class="card">
    <BasicTable
      :columns="columnsBarberService"
      :data="barberServices"
      :create="true"
      @new="onNew"
    >
      <template #header>
        <h1>Layanan Pegawai</h1>
      </template>
      <template #column>
        <Column header="Aksi">
          <template #body="context">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-warning mr-2"
              @click="edit(context.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              @click="confirmDelete(context.data)"
            />
          </template>
        </Column>
      </template>
    </BasicTable>
  </div>

  <Dialog
    v-model:visible="showDialog"
    :style="{ width: '450px' }"
    header="Layanan Pegawai"
    :modal="true"
  >
    <div class="p-fluid">
      <form @submit.prevent="submitService(formData)" class="">
        <div class="field">
          <label for="salon">Salon</label>
          <div>{{ salonName }}</div>
        </div>

        <div class="field">
          <label for="salon">Harga</label>
          <Dropdown
            v-model="formData.service_id"
            @change="(e) => {}"
            :options="salonServices"
            optionLabel="name"
            optionValue="id"
            placeholder="Pilih Layanan">
          </Dropdown>
        </div>

        <div class="field tw-mb-10">
          <label for="salon">Harga</label>
          <InputNumber
            id="currency-idr"
            v-model="formData.price"
            mode="currency"
            currency="IDR"
            locale="id-ID"
          />
        </div>

        <div class="w-full flex justify-content-between">
          <div>
            <Button label="Batal" class="p-button-danger" @click="dismissModal" />
          </div>
          <div>
            <Button label="Simpan" class="p-button-info" type="submit" :disabled="!formData.price && !formData.service_id" />
          </div>
        </div>
      </form>

   <!--    <FormKit
        type="form"
        id="barber-service"
        v-model="formData"
        :actions="false"
        :classes="{ messages: 'tw-text-red-500 tw-text-center tw-mt-4' }"
        @submit="submitService"
      >
        <FormKit
          name="service_id"
          type="select"
          label="Layanan"
          validation="required"
          placeholder="Pilih Layanan"
          :classes="defaultFormikListClass"
          :options="salonServices"
        >
          <template #input="context">
            <div class="p-fluid">
              <Dropdown
                v-model="context.value"
                @change="(e) => {
                  context.node.input(e.value);
                }"
                :options="context.options"
                optionLabel="name"
                optionValue="id"
                :placeholder="context.placeholder"
              >
                <template #option="optionContext">
                  <option
                    v-if="!optionContext.option.attrs?.hidden"
                    :key="optionContext.index"
                    :value="optionContext.option.id"
                  >
                    {{ optionContext.option.name }}
                  </option>
                  <option
                    v-else
                    :key="`_${optionContext.index}`"
                    :disabled="true"
                    :value="optionContext.option"
                  >
                    Not Selected
                  </option>
                </template>
              </Dropdown>
            </div>
          </template>
        </FormKit>
        <FormKit
          name="price"
          type="text"
          label="Harga"
          validation="required|number"
          validation-visibility="dirty"
          :classes="defaultFormikListClass"
        >
          <template #input="context">
            <InputNumber
              id="currency-idr"
              v-model="context.value"
              @input="(e) => {
                context.node.input(e.value);
              }"
              mode="currency"
              currency="IDR"
              locale="id-ID"
            />
          </template>
        </FormKit>
      </FormKit> -->
    </div>
  <!--  <template #footer>
      <div class="w-full flex justify-content-between">
        <div>
          <Button label="Batal" class="p-button-danger" @click="dismissModal" />
        </div>
        <div>
          <Button label="Simpan" class="p-button-info" @click="submit" />
        </div>
      </div>
    </template> -->
  </Dialog>

  <Dialog
    header="Korfirmasi"
    v-model:visible="showConfirmDelete"
    :style="{width: '350px'}"
    :modal="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>Apakah Anda yakin ingin melanjutkan?</span>
    </div>
    <template #footer>
      <Button label="Tidak" icon="pi pi-times" @click="showConfirmDelete = false" class="p-button-text"/>
      <button class="p-button p-component tw-px-10" disabled v-if="loadingDelete">
        <ProgressSpinner
          stroke-width="5"
          animation-duration="2s"
          style="width: 30px; height: 30px;" />
      </button>
      <Button label="Ya" icon="pi pi-check" @click="deleteService(barberServiceTemp)" class="p-button" autofocus v-else/>
    </template>
  </Dialog>
</template>

<script setup>
import BasicTable from "@/components/dashboard/BasicTable";
import { useBarberService } from "@/composables/barber";
import { defineProps, defineEmits, onMounted, ref, watch } from "vue";
import { useSalonServiceDropdown } from "@/composables/salon_service";
// import { defaultFormikListClass } from "@/constants/formik";
// import { reset, submitForm } from "@formkit/vue";
import { useCommonUtils } from "@/composables/utils";
import { useToast } from "primevue/usetoast";

const props = defineProps({
  barberId: String,
  salonId: String,
  salonName: String,
});

const emit = defineEmits(["getBarberServices"]);

const formData = ref({});
const showDialog = ref(false);
const isEdit = ref(false);
const barberServiceId = ref(null);
const showConfirmDelete = ref(false);
const barberServiceTemp = ref(null);
const loadingDelete = ref(false);
const toast = useToast()

const { barberServices, getBarberService, create, update, deleteBarberService } = useBarberService(
  props.barberId
);
const { salonServices, getSalonServiceDropdown } = useSalonServiceDropdown();
const { formatCurrency } = useCommonUtils();

const columnsBarberService = [
  { field: "no", header: "No" },
  { field: "service_name", header: "Layanan" },
  { field: "price", header: "Harga", process: (data) => formatCurrency(data) },
];

const onNew = () => {
  showDialog.value = true;
};

const edit = (data) => {
  isEdit.value = true;
  formData.value = {
    service_id: data.service_id,
    price: data.price,
    admin_fee: data.admin_fee,
  };
  barberServiceId.value = data.service_id;
  showDialog.value = true;
};

const dismissModal = () => {
  showDialog.value = false;
};

// const submit = () => {
//   console.log(formData.value);
//   submitForm("barber-service");
// };

const submitService = async (data) => {
  console.log(data);
  if (data.price <= 0) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: `Harga harus lebih dari ${formatCurrency(0)}`,
      life: 3000,
    });
    return
  }

  if (isEdit.value) {
    let response = await update(props.barberId, barberServiceId.value, data);
    isEdit.value = false;
    barberServiceId.value = null;
    if (response.code === 200) {
      toast.add({
        severity: 'success',
        summary: 'Success!',
        detail: response.message,
        life: 3000,
      });
    } else {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: response.message,
        life: 3000,
      });
    }
  } else {
    let response = await create(props.barberId, data);
    if (response.code === 200) {
      toast.add({
        severity: 'success',
        summary: 'Success!',
        detail: response.message,
        life: 3000,
      });
    } else {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: response.message,
        life: 3000,
      });
    }
  }
  await getBarberService(props.salonName);
  //reset("barber-service");
  dismissModal();
  emit("getBarberServices", barberServices);
};

const confirmDelete = (data) => {
  showConfirmDelete.value = true;
  barberServiceTemp.value = data
}

const deleteService = async (data) => {
  loadingDelete.value = true

  let response = await deleteBarberService(data.id)

  if (response.status === 200) {
    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: response.data.message,
      life: 3000,
    });

    await getBarberService(props.salonName)
    emit("getBarberServices", barberServices);
  } else {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: response.data.message,
      life: 3000,
    });
  }

  loadingDelete.value = false
  showConfirmDelete.value = false
}

watch(
  () => props.salonId,
  async (salonId) => {
    if (salonId) {
      await getSalonServiceDropdown({ salon_id: salonId });
    }
  }
);

onMounted(async () => {
  await getBarberService(props.salonName);
  emit("getBarberServices", barberServices);
});
</script>

<style scoped></style>
