<template>
  <div class="card">
    <BasicTable
      :columns="columnsBarberAvailability"
      :data="barberAvailabilities"
      :create="barberServices.length ? true : false"
      @new="onNew"
    >
      <template #header>
        <h1>Jadwal Pegawai</h1>
      </template>
      <template #column>
        <Column header="Aksi">
          <template #body="context">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-warning mr-2"
              @click="edit(context.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              @click="confirmDelete(context.data)"
            />
          </template>
        </Column>
      </template>
    </BasicTable>
  </div>

  <Dialog
    v-model:visible="showBulkDialog"
    :style="{ width: '450px' }"
    header="Jadwal Pegawai"
    :modal="true"
    @hide="dismissModal"
  >
    <div class="tw-flex tw-justify-center">
      <Calendar
        :inline="true"
        v-model="bulkData"
        :min-date="today"
        :manual-input="true"
        selection-mode="range"
        panel-class="tw-border-none tw-w-full"
        class="tw-w-full"
        @date-select="dateSelected"
      >
        <template #footer>
          <div class="tw-mt-8">
            <div>
              <h3 class="tw-font-bold">Spesifik Hari</h3>
              <div class="tw-space-y-0.5 tw-mt-1">
                <div v-for="day in days" :key="day.name" class="tw-space-x-2">
                  <Checkbox
                    v-model="selectedDays"
                    :id="day.name"
                    :name="day.name"
                    :value="day.value"
                    :disabled="day.disabled"
                  />
                  <label :for="day.name" :class="{'tw-text-gray-300': day.disabled}">{{ day.label }}</label>
                </div>
              </div>
              <div class="tw-mt-4 tw-space-y-2">
                <div class="p-fluid">
                  <h3 class="tw-font-bold">Jam Mulai</h3>
                  <VueTimepicker
                    format="HH:mm"
                    :minute-interval="10"
                    :blur-delay="50"
                    close-on-complete
                    manual-input
                    advanced-keyboard
                    fixed-dropdown-button
                    auto-scroll
                    v-model="startTime"
                    input-class="p-inputtext p-component w-full"
                    input-width="100%"
                    v-on:keyup.enter="$event.target.blur()">
                  </VueTimepicker>
                </div>
                <div class="tw-flex tw-flex-col mb-5">
                  <h3 class="tw-font-bold">Berakhir</h3>
                  <VueTimepicker
                    format="HH:mm"
                    :minute-interval="10"
                    :blur-delay="50"
                    close-on-complete
                    manual-input
                    advanced-keyboard
                    fixed-dropdown-button
                    auto-scroll
                    v-model="endTime"
                    input-class="p-inputtext p-component w-full"
                    input-width="100%"
                    v-on:keyup.enter="$event.target.blur()">
                  </VueTimepicker>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Calendar>
    </div>
    <template #footer>
      <div class="w-full flex justify-content-between">
        <div>
          <Button label="Batal" class="p-button-danger" @click="dismissModal" />
        </div>
        <div>
          <Button label="Simpan" class="p-button-info" @click="submitBulk" />
        </div>
      </div>
    </template>
  </Dialog>

  <Dialog
    v-model:visible="showDialog"
    :style="{ width: '450px' }"
    header="Jadwal Pegawai"
    :modal="true"
    @hide="dismissModal"
  >
    <div class="p-fluid">
      <FormKit
        type="form"
        id="barber-availability"
        v-model="formData"
        :actions="false"
        :classes="{ messages: 'tw-text-red-500 tw-text-center tw-mt-4' }"
        @submit="submitAvailability"
      >
        <FormKit
          type="datetime-local"
          name="start"
          label="Mulai"
          validation="required"
          validation-visibility="live"
          :min="today"
          :max="formData.end"
          :classes="defaultFormikListClass"
        >
          <template #input="context">
            <Calendar
              v-model="context.value"
              :show-time="true"
              :min-date="today"
              :max-date="formData.end"
              :stepMinute="10"
              @date-select="
                (e) => {
                  context.node.input(e);
                }
              "
            />
          </template>
        </FormKit>
        <FormKit
          type="datetime-local"
          name="end"
          label="Berakhir"
          validation="required"
          validation-visibility="live"
          :min="formData.start"
          :classes="defaultFormikListClass"
        >
          <template #input="context">
            <Calendar
              v-model="context.value"
              :show-time="true"
              :min-date="formData.start"
              :stepMinute="10"
              @date-select="
                (e) => {
                  context.node.input(e);
                }
              "
            />
          </template>
        </FormKit>
      </FormKit>
    </div>
    <template #footer>
      <div class="tw-flex tw-justify-between">
        <div>
          <Button label="Batal" class="p-button-danger" @click="dismissModal" />
        </div>
        <div>
          <Button label="Simpan" class="p-button-info" @click="submit" />
        </div>
      </div>
    </template>
  </Dialog>

  <Dialog
    header="Korfirmasi"
    v-model:visible="showConfirmDelete"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span>Apakah Anda yakin ingin melanjutkan?</span>
    </div>
    <template #footer>
      <Button
        label="Tidak"
        icon="pi pi-times"
        @click="showConfirmDelete = false"
        class="p-button-text"
      />
      <button
        class="p-button p-component tw-px-10"
        disabled
        v-if="loadingDelete"
      >
        <ProgressSpinner
          stroke-width="5"
          animation-duration="2s"
          style="width: 30px; height: 30px"
        />
      </button>
      <Button
        label="Ya"
        icon="pi pi-check"
        @click="deleteAvailability(barberAvailabilityTemp)"
        class="p-button"
        autofocus
        v-else
      />
    </template>
  </Dialog>
</template>

<script setup>
import BasicTable from "@/components/dashboard/BasicTable";
import { useBarberAvailability } from "@/composables/barber";
import { defineProps, onMounted, ref } from "vue";
import { defaultFormikListClass } from "@/constants/formik";
import { reset, submitForm } from "@formkit/vue";
import moment from "moment";
import { useToast } from "primevue/usetoast";

const toast = useToast();
const today = new Date();
const props = defineProps({
  salonName: String,
  salonId: String,
  barberId: String,
  barberServices: Array,
});
const formData = ref({
  start: today,
  end: null,
});
const showDialog = ref(false);
const isEdit = ref(false);
const showBulkDialog = ref(false);
const bulkData = ref(null);
const selectedDays = ref([]);
const startTime = ref(null);
const endTime = ref(null);
const barberAvailabilityId = ref(null);
const showConfirmDelete = ref(false);
const barberAvailabilityTemp = ref(null);
const loadingDelete = ref(false);

const {
  barberAvailabilities,
  getBarberAvailability,
  create,
  update,
  createBulk,
  deleteBarberAvailability,
} = useBarberAvailability(props.barberId);

const columnsBarberAvailability = [
  { field: "no", header: "No" },
  { field: "start", header: "Start" },
  { field: "end", header: "End" },
];

const days = [
  { name: "monday", label: "Senin", value: "Monday", disabled: true, },
  { name: "tuesday", label: "Selasa", value: "Tuesday", disabled: true, },
  { name: "wednesday", label: "Rabu", value: "Wednesday", disabled: true, },
  { name: "thursday", label: "Kamis", value: "Thursday", disabled: true, },
  { name: "friday", label: "Jumat", value: "Friday", disabled: true, },
  { name: "saturday", label: "Sabtu", value: "Saturday", disabled: true, },
  { name: "sunday", label: "Minggu", value: "Sunday", disabled: true, },
];

const onNew = () => {
  showBulkDialog.value = true;
  reset("barber-availability");
};

const dismissModal = () => {
  showBulkDialog.value = false;
  bulkData.value = null;
  selectedDays.value = [];
  startTime.value = null;
  endTime.value = null;
  showDialog.value = false;
  isEdit.value = false;
  reset("barber-availability");
};

const edit = (data) => {
  isEdit.value = true;
  formData.value.start = moment(data.start, "DD/MM/YYYY HH:mm").toDate();
  formData.value.end = moment(data.end, "DD/MM/YYYY HH:mm").toDate();
  barberAvailabilityId.value = data.id;
  showDialog.value = true;
};

const confirmDelete = (data) => {
  showConfirmDelete.value = true;
  barberAvailabilityTemp.value = data;
};

const deleteAvailability = async (data) => {
  loadingDelete.value = true;

  let response = await deleteBarberAvailability(data.id);

  if (response.code >= 200 && response.code <= 210) {
    getBarberAvailability(props.salonName);
    toast.add({
      severity: "success",
      summary: "Success",
      detail: response.message,
      life: 3000,
    });
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: response.message,
      life: 3000,
    });
  }

  loadingDelete.value = false;
  showConfirmDelete.value = false;
};

const submit = () => {
  submitForm("barber-availability");
};

const submitBulk = async () => {
  if (!bulkData.value || (bulkData.value && bulkData.value < 0)) return;
  if (selectedDays.value.length === 0) return;
  if (!startTime.value || !endTime.value) return;
  const startDate = moment(bulkData.value[0]).format("YYYY-MM-DD");
  const endDate = moment(bulkData.value[1]).format("YYYY-MM-DD");
  const start = `${startTime.value.HH}:${startTime.value.mm}`;
  const end = `${endTime.value.HH}:${endTime.value.mm}`;

  const formData = {
    barber_id: Number(props.barberId),
    date_from: startDate,
    date_thru: endDate,
    selected_days: selectedDays.value,
    start: start,
    end: end,
  };

  await createBulk(props.barberId, formData);
  await getBarberAvailability(props.salonName);
  dismissModal();
};

const submitAvailability = async (data) => {
  const formData = {
    start: moment(data.start).format("YYYY-MM-DD HH:mm:ss"),
    end: moment(data.end).format("YYYY-MM-DD HH:mm:ss"),
    barber_id: parseInt(props.barberId),
  };
  if (isEdit.value)
    await update(props.barberId, barberAvailabilityId.value, formData);
  else await create(formData);
  await getBarberAvailability(props.salonName);
  dismissModal();
};

const dateSelected = () => {
  selectedDays.value = [];

  let startDate = bulkData.value[0] !== null ? moment(bulkData.value[0]) : null;
  let endDate = bulkData.value[1] !== null ? moment(bulkData.value[1]) : null;
  let selectedCalendar = [];

  days.forEach(day => {
    day.disabled = true
  });

  if (startDate === null || endDate === null) return

  let now = startDate.clone()
  let end = endDate.clone().add(1, 'days')
  while (now.isBefore(end)) {
    selectedCalendar.push(now.format('dddd'));
    now.add(1, 'days');
  }

  days.forEach(day => {
    if (selectedCalendar.includes(day.value)) {
      day.disabled = false
    }
  });
}

onMounted(async () => {
  startTime.value = moment()
  await getBarberAvailability(props.salonName);
});
</script>

<style scoped>
</style>
