<template>
  <div>
    <Toolbar v-if="create" class="tw-mb-8 tw-p-3">
      <template #start>
        <div>
          <Button
            label="Tambah"
            icon="pi pi-plus"
            class="p-button-sm p-button-success tw-bg-blue-light"
            @click="this.$emit('new')"
          />
        </div>
      </template>
    </Toolbar>

    <DataTable :value="data" responsiveLayout="scroll">
      <template #header>
        <slot name="header" />
      </template>

      <div v-if="columns.length > 0">
        <Column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :header="column.header"
        >
          <template v-if="column.process" #body="slotProps">
            {{ column.process(slotProps.data[column.field]) }}
          </template>
        </Column>
        <slot name="column" />
      </div>
      <template #empty>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold">
          Tidak ada data yang ditemukan
        </div>
      </template>
      <template #loading>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
          Loading records, please wait...
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  name: "BasicTable",
  props: {
    data: Array,
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    create: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>
